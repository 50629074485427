import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex);
let _vue = new Vue();
const isProduction = process.env.NODE_ENV === 'production';

if (!isProduction) {
	log('DEV');
	// allow ajax cross
	$.ajaxSetup({
		crossDomain: true,
		xhrFields: {
			withCredentials: true
		}
	});
}

// TODO -changes to config.json no longer apply after build- find an other way to load config json data into project
const config = require('@/../../config.json');

const server_addr_env_dev = window.location.protocol + '//' + window.location.hostname + ":" + config.serverPort;
const SERVER_ADDRESS = isProduction ? window.location.origin : server_addr_env_dev;

const state = {
	SERVER_ADDRESS,
	loading: false
};

const getters = {};

const mutations = {
	SET_LOADING(state, payload) {
		state.loading = payload
	}
};

let store = new Vuex.Store({
	state,
	getters,
	mutations
});

export default store;

/**
 * @param url <String> request url
 * @param data <JSON> request data
 * @param use_loader <Boolean> show the application loader while request is in progress or not
 * @param debug <Boolean> console request data
 * @description send http request
 *
 */
store.dispatchAsync = function (url, data, use_loader = false, debug = true) {

	const log = '`store.dispatchAsync`\n\turl: %s, \n\tdata: %o';
	data = JSON.stringify(data);
	url = SERVER_ADDRESS + url;

	return new Promise((resolve, reject) => {
		// show loader
		use_loader && store.commit('SET_LOADING', true);

		let headers = {"Content-Encoding": "gzip", "Content-Type": "application/json; charset=utf-8"};
		/*if (!isProduction)
			headers = {"Content-Type": "text/plain; charset=utf-8"};*/

		$.ajax({
			url,
			headers,
			data,
			type: "POST",
			datatype: "json",
			complete: () => {
				// hide loader
				use_loader && store.commit('SET_LOADING', false);
			}
		}).done(resp => {
			console.log(log + '\n\tresp: %o', url, debug ? data : '???', resp);
			if (resp.succeeded) {
				resolve(resp.data);
			} else {
				// if session is expiered
				if (resp.message === 4) {
					_vue.$notify({
						group: 'user-message',
						type: 'error', // warn , error, success, info
						text: _vue.$SHARED.messages[4]
					});
					router.push({name: "login"});
				}
				reject(resp.message);
			}
		}).fail(err => {
			console.log(log + '\n\tresp: %o', resp);
			reject(0);
		});
	})
};
