const fields = {};

const services = {
	verify: '/verify',
	dossier:{
		getByToken:'/getDossier'
	}
};

const messages = {
	// server error codes
	'-1': "TECHNICAL_ERROR", // event bus replay delay
	0: "TECHNICAL_ERROR",
	1: "USER_OR_PASSWORD_INCORRECT",
	2: "DUPLICATED_USERNAME",
	3: "WRONG_PASSWORD",
	4: "SESSION_EXPIRED",
	5: "DOCUMENT_NOT_FOUND",
	6: "DOCUMENT INTROUVABLE",
	10: "Merci de refaire la captcha",
	20: "information invalide",
	21: "statut invalide",
	22: "Email est déjà utilisé merci de saisir un autre",
	25: "Vous avez un dossier encours de traitement",
	26: "Votre dossier a été traité , merci de consulter votre e-mail",
	_200:"fichier  charger avec success",
	technical_error: "TECHNICAL_ERROR"
};

const collections = {
	user: "user",
	etudiants: "etudiants"
};


export default {
	fields,
	services,
	messages,
	collections
};