<template>
	<div id="app">
		<notifications group="user-message" position="right top" :speed="1" :duration="-1" class="user-message"/>
		<header id="header">
			<img src="@/assets/logo.png" id="logo" alt="">
		</header>
		<!--loader-->
		<div :class="Loading?'ui active inverted dimmer':'ui dimmer'" style="position: fixed">
			<div class="ui big text loader">
				Loading...
			</div>
		</div>
		<router-view class="py-5"></router-view>
	</div>
</template>

<script>
	// import semantic-ui dependencies
	import '../libs/semantic-ui/semantic.min.js';
	import '../libs/semantic-ui/semantic.min.css';

	export default {
		name: 'App',
		computed: {
			Loading() {
				return this.$store.state.loading;
			}
		}
	}
</script>

<style>
	@import './assets/css/fonts.css';

	/**
	* start of global css
	*
	*/

	/*variables*/
	:root {
		--theme-color: #73bae6;
	}

	@font-face {
		font-family: 'Roboto-Regular';
		src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	*:not(i) {
		font-family: 'Montserrat' !important;
	}

	a:hover {
		cursor: pointer !important;
	}

	.nowrap {
		white-space: nowrap !important;
	}

	/** component transition when using router */
	.fade-leave-active, .fade-enter-active {
		transition: all .2s cubic-bezier(0.1, 0.1, 0.1, 0.1);
	}

	.fade-enter, .fade-leave-to {
		transform: translateX(10px);
		opacity: .1;
	}

	.jump-leave-active, .jump-enter-active {
		transition: all .2s cubic-bezier(0.1, 0.1, 0.1, 0.1);
	}

	.jump-enter, .jump-leave-to {
		opacity: .8;
	}


	/*disable text selection*/
	* {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/**
	*
	* end of global css
	*/

	#header {
		align-items: center;
		background: #f7f7f7;
		cursor: default;
		height: 150px;
		left: 0;
		justify-content: center;
		align-items: center;
		top: 0;
		width: 100%;
		z-index: 9;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.075);
		text-align: center;
	}

	#logo {
		height: 150px;

	}

	.text-center {
		text-align: center !important;
	}

	.px-0 {
		padding-right: 0em !important;
		padding-left: 0em !important;
	}

	.py-5 {
		margin-top: 40px;
		margin-bottom: 50px;
	}

	.flex {
		display: flex !important;
	}

	.items-center {
		align-items: center !important;

	}

	.justify-center {
		justify-content: center !important;
	}

	.translate-x-1\/2 {
		--transform-translate-x: 50% !important;
	}

	.-translate-x-1\/2 {
		--transform-translate-x: -50% !important;
	}

	.absolute {
		position: absolute;
	}

	.faded {
		opacity: 0.2;
	}

	.right-full {
		right: 100%;
	}

	.bottom-0 {
		bottom: 0;
	}

	.transform {
		--transform-translate-x: 0;
		--transform-translate-y: 0;
		--transform-rotate: 0;
		--transform-skew-x: 0;
		--transform-skew-y: 0;
		--transform-scale-x: 1;
		--transform-scale-y: 1;
		transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
	}

	.left-full {
		left: 100%;
	}


	.header-title {
		font-size: 2.25rem !important;
		font-weight: 800;
		color: #161e2e;
	}

	.header-title-2 {
		font-size: 2rem !important;
		font-weight: 800;
		color: rgba(56, 53, 60, 0.93) !important;
		margin-bottom: 2em !important;
	}

	.header-subtitle {
		color: #6b7280;
		text-align: center;
		font-size: 1.125rem;
	}


	@media only screen and (max-width: 700px) {
		svg.dots {
			display: none !important;
		}
	}
</style>
