import Vue from 'vue'
import VueRouter from 'vue-router'

// views
const home = () => import('@/views/home');
const viewDocument = () => import('@/views/document');

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: home,
	},
	{
		path: '/document',
		name: 'document',
		component: viewDocument,
		props:true
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router
