import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import shared from "./shared";

Vue.config.productionTip = false;
Vue.prototype.$SHARED = shared;

// notifications
import Notifications from 'vue-notification'
Vue.use(Notifications);

// Global event bus
// Vue.prototype.$eb = new Vue();

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
